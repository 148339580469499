import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../components/layouts/secondary-landing";
import HeroChevron from "../../components/hero/hero-chevron";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import ContactInfo from "../../components/contact-info/contact-info";
import SEO from "../../components/seo/seo";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";
import MktoForm from "../../components/mkto-form/mkto-form";
import TwoColumnSectionWithImage from "../../components/custom-widgets/two-column-section-with-image";
import List from "../../components/custom-widgets/list";
import Button from "../../components/custom-widgets/button";
import BestBanksDefault from "../../components/best-banks/best-banks-default";

//helper functions
import showMktoForm from "../../helpers/showMktoForm";

const CommercialClientConnect = () => {
  const title = "Commercial Client Connect";
  const description =
    "WAFD Client Connect is the gateway to securely send documents to your banker and apply for new commercial lending products.";
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/commercial-banking/commercial-client-connect/hero-wafd-client-connect-011023-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/commercial-banking/commercial-client-connect/hero-wafd-client-connect-011023-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/commercial-banking/commercial-client-connect/hero-wafd-client-connect-011023-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/commercial-banking/commercial-client-connect/hero-wafd-client-connect-011023-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/commercial-banking/commercial-client-connect/hero-wafd-client-connect-011023-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/commercial-banking/commercial-client-connect/hero-wafd-client-connect-011023-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/commercial-banking/commercial-client-connect/hero-wafd-client-connect-011023-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/commercial-client-connect"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-wafd-client-connect-011023-250.jpg"
      }
    ]
  };

  const heroChevronData = {
    id: "commercial-client-connect-hero",
    ...getHeroImgVariables(imgData),
    altText: "Businesswoman sitting in an office with a laptop",
    chevronBgClass: "bg-info",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Work Smarter, Not Harder"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-btn-1",
            class: "btn-white",
            onClick: () => showMktoForm(1067),
            type: "button",
            text: "Contact Us"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/commercial-banking",
      title: "Commercial Banking"
    },
    {
      id: 2,
      active: true,
      title: "Commercial Client Connect"
    }
  ];

  const FeaturesContent = () => {
    return (
      <>
        <h2>Client Connect Features</h2>
        <h5 className="mb-0 text-success font-weight-bold">New Products</h5>
        <p>
          Initiate a full suite of commercial lending products through a simple application that is sent directly to
          your banker.
        </p>
        <h5 className="mb-0 text-success font-weight-bold">Document Transfer</h5>
        <p>
          Securely deliver documents to your banker from your desktop using a simple "drag and drop" to get them in the
          right place.
        </p>
        <h5 className="mb-0 text-success font-weight-bold">Convenient Compliance</h5>
        <p>
          As your banker ensures the continuing health of your company, they will request financial statements and tax
          returns securely through the Connect portal. Simply "drag and drop" and get on with your day.
        </p>
        <Button
          id={"features-cta-contact-us"}
          class={"btn-primary no-min-width w-100 w-sm-auto"}
          onClick={() => showMktoForm(1067)}
          showIcon={false}
          type={"button"}
          text={"Contact Us"}
        />
      </>
    );
  };

  const benefitsListData = {
    accountName: "client-connect-list",
    items: [
      {
        id: 1,
        text: "Uses existing logins so you don't have to use multiple usernames and passwords"
      },
      {
        id: 2,
        text: "Simple navigation and tools that will guide you along the way"
      },
      {
        id: 3,
        text: "Safe and secure file transfer"
      },
      {
        id: 4,
        text: "Expand your business opportunities with us"
      }
    ]
  };

  const BenefitsContent = () => {
    return (
      <>
        <h2>The WAFD Client Connect Advantage</h2>
        <h4 className="text-success font-weight-semi-bold">
          A new, easy way to do business with WaFd and your banker:
        </h4>
        <List {...benefitsListData} />
        <Button
          id={"benefits-cta-contact-us"}
          class={"btn-primary no-min-width w-100 w-sm-auto"}
          onClick={() => showMktoForm(1067)}
          showIcon={false}
          type={"button"}
          text={"Contact Us"}
        />
      </>
    );
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <MktoForm />
      <section className="container">
        <h1>WAFD Client Connect</h1>
        <h3 className="mb-0">
          WAFD Client Connect is the gateway to securely send documents to your banker and apply for new commercial
          lending products as an existing client.
        </h3>
      </section>
      <TwoColumnSectionWithImage isGray={true} contentOnLeft={false}>
        {{
          image: (
            <StaticImage
              src="../../images/thumbnail-client-connect-031224.jpg"
              alt="Man working on a laptop in office smiling."
              placeholder="blurred"
              quality="100"
              className="border-radius-12 mb-3"
            />
          ),
          content: <FeaturesContent />
        }}
      </TwoColumnSectionWithImage>
      <TwoColumnSectionWithImage>
        {{
          image: (
            <StaticImage
              src="../../images/thumbnail-client-connect-advantage-031224.jpg"
              alt="Woman holding a laptop in office smiling."
              placeholder="blurred"
              quality="100"
              className="border-radius-12 mb-3"
            />
          ),
          content: <BenefitsContent />
        }}
      </TwoColumnSectionWithImage>
      <ContactInfo type="commercial" />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default CommercialClientConnect;
